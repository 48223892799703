<template>
  <div id="login" v-loading="loading">
    <div class="login_center">
        <div class="left_bg">
          <img src="../../img/login_bg.png" width=325.5 height=420px />
        </div>
        <div class="login-box">
          <div>
              <div class="company_logo">
                <img src="../../img/login_logo.png" />
              </div>
              <p class="company_p">迪倍特运维管理云平台</p>
              <!-- <p class="company_p">乌审旗鑫浩泰自来水有限公司</p>
              <p class="company_p">运维管理云平台</p> -->
          </div>
          <div class="input-box">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="">
              <el-form-item label="" prop="account">
            <!-- <input v-model="form.account" type="text" placeholder="用户名" > -->
                <el-input v-model="form.account" placeholder="用户名" prefix-icon="el-icon-user" c></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input v-model="form.password" placeholder="密码" type="password" prefix-icon="el-icon-unlock"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="btn" @click="login">登 录</el-button>
              </el-form-item>
            </el-form>
          </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import md5 from 'md5'
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {}
    }
  },
  methods: {
    login() {
      if(!this.form.account) return
      if(!this.form.password) return 
      this.loading = true
      this.$ajax.post('accountLogin', {
        phone: this.form.account,
        pwd: md5(this.form.password)
      }).then(res => {
        if (res.code == 0) {
          this.loading = false
          sessionStorage.setItem('name', res.data.staffName)
          sessionStorage.setItem('c', res.data.companyId)
          sessionStorage.setItem('r', res.data.roleId)
          sessionStorage.setItem('id', res.data.staffId)
          sessionStorage.setItem('menus', JSON.stringify(res.data.menus))

          let mArr = []
          if(res.data.roleId != 0) {
            res.data.menus.forEach((item)=>{
              if(item.url=='/dashboard') {
                mArr.push(item.url)
              }
              item.childTree.forEach(i=>{
                mArr.push(i.url)
              })
            })
            this.$router.push(mArr[0])
          }else {
            this.$router.push('/dashboard')
          }
        }
      }).catch(error => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
#login {
  background: url("../../img/2.webp") no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
  .login_center{
    margin-top: 12%;
    display: flex; 
    /*div内容水平居中*/
    justify-content: center;
  }
  .left_bg{
      float: left;
  }
  .company_logo{
    text-align: center;
    margin-top: 10px;
    img {
      width: 50%;
    }
  }
  .company_p{
    font-size:18px;
    color:#010101;
    font-weight: bold;
    align-content: center;
    text-align: center;
    // margin-top: 8px;
    height: 20px;
    line-height: 20px;
  }

  /deep/.el-input__inner{
    color: rgb(17, 17, 17);
    caret-color: rgb(15, 15, 15);
    background: none;
    border: none;
    border-bottom: 1px solid rgba(109, 107, 107, 0.2);
    border-radius: 0;
  }


 
  .login-box {
    width: 325.5px;
    height: 420px;
    float: left;
    color: #fff;
    top: calc(~"40% - 160px");
    left: calc(~"50% - 160px");
    padding: 30px 20px;
    // border-radius: 4px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    .title {
      padding-left: 20px;
      border-bottom: 1px solid #d8d8d8;
      line-height: 52px;
    }
    .input-box {
      margin-top: 20%;
    }
    .btn {
      margin-top: 5%;
      width: 100%;
      background: #2C5BFF;
      color: #fff;
      border: none;
    }
  }
}
</style>

<style lang="less">
#login {
  .el-input__inner {
    color: #fff;
    caret-color: #fff;
    // margin-left: 30px;
    // padding-left: 0;
    // width: 240px;
    background: none;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0;
  }
}
</style>